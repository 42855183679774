import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';



const useStyles = makeStyles(() => ({
  img: {
    borderRadius: '6px',
    maxWidth: '150px',
    maxHeight: '150px',
    width: 'auto',
    height: 'auto',
  },
  imgContainer: {
    width: '150px',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CaptureImageWrapper = ({ imageUrl, token }) => {
  const classes = useStyles();

  const [ imageSrc, setImageSrc ] = useState('');

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const resizedUrl = `${imageUrl}?width=150&height=150`;
        const response = await fetch(resizedUrl, { headers: { 'Authorization': `Bearer ${token}` } });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const imageObjectURL = URL.createObjectURL(blob);
        setImageSrc(imageObjectURL);
      } catch (error) {
        console.error('Error fetching the image:', error);
      }
    };

    fetchImage();
  }, [ imageUrl, token ]);

  return (imageSrc ?
    <Grid container item xs={6} direction="column">
      <Box className={classes.imgContainer}>
        <img src={imageSrc} className={classes.img}/>
      </Box>
    </Grid> :
    <Grid container item xs={2} direction="column" />
  );
};

CaptureImageWrapper.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,

};

export { CaptureImageWrapper } ;