import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Avatar, Typography } from '@material-ui/core';

import { Cards, LabelChip } from 'src/components';
import { makeStyles } from '@material-ui/styles';


const categoryList = {
  EX: { text: 'Extinto', backgroundColor: 'black', textColor: 'white' },
  EW: { text: 'Extinta en estado silvestre', backgroundColor: '#4C3A37', textColor: 'white' },
  CR: { text: 'En peligro crítico', backgroundColor: '#D26932', textColor: 'white' },
  EN: { text: 'En peligro', backgroundColor: '#E19943', textColor: 'white' },
  VU: { text: 'Vulnerable', backgroundColor: '#FBE912', textColor: 'black' },
  NT: { text: 'Casi amenazada', backgroundColor: '#A6CE82', textColor: 'black' },
  LC: { text: 'Preocupación menor', backgroundColor: '#88B035', textColor: 'black' },
  DD: { text: 'Datos insuficientes', backgroundColor: '#D6D8D5', textColor: 'black' },
  R: { text: 'Rara', backgroundColor: '#A9CD8A', textColor: 'black' },
};

const useStyles = makeStyles(theme => ({
  scientificName: {
    fontStyle: 'italic',
  },
  year: {
    marginRight: theme.spacing(0.5),
  },
  avatar: {
    width: '75px',
    height: '75px',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: '10%',
    border: '1px solid',
    borderColor: theme.palette.common.gray400,
    cursor: 'pointer',
  },
  footer: {
    fontSize: '0.6em',
    color: theme.palette.common.gray800,
  },
  boxCategory: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
  },
  boxCategoryFooter: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '11px',
  },
  categoryText: {
    marginLeft: theme.spacing(0.4),
  },
}));

const EeccList = ({ eeccs, eeccTypeFilter, actions }) => {
  const classes = useStyles();

  const cardsRef = useRef();

  useEffect(() => {
    const cardPagination = cardsRef.current.getPagination();
    cardPagination.jump(0);
  }, [ eeccTypeFilter ]);

  const columns = [
    {
      title: 'Nombre científico',
      render: ({ scientificName }) => <Box className={classes.scientificName}>{scientificName}</Box>,
    },
    {
      title: 'Año',
      render: ({ years }) =>
        <>
          {years.map((year, idx) =>
            <LabelChip className={classes.year} key={`${year}-${idx}`} color="default" variant="outlined" label={year} />,
          )}
        </>,
    },
    {
      title: 'Categoría',
      render: ({ category }) => {
        const validCategory = category.map(category => category.slice(0, 2).replace(/\s/g, '').toUpperCase())[0];
        const color = categoryList[validCategory].backgroundColor || categoryList['DD'].backgroundColor;
        const textColor = categoryList[validCategory].textColor || categoryList['DD'].textColor;
        return <Box className={classes.boxCategory} style={{
          backgroundColor: color,
          color: textColor,
        }}>
          {validCategory}
        </Box>;
      },
    },
    {
      title: 'Referencia',
      render: ({ scientificName, eeccInfo }) =>
        eeccInfo.url ? (
          <>
            <Box display="flex" mt={1} justifyContent="center" width="100%">
              <Avatar
                src={eeccInfo.thumbnail}
                className={classes.avatar}
                imgProps={{
                  style: {
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                    borderRadius: '10%',
                  },
                }}
                variant="square"
                onClick={() => actions.toggleDialog({ data: {
                  pictureData: {
                    pictures: [ eeccInfo.imgUrl ],
                    title: scientificName,
                    link: eeccInfo.url,
                    author: eeccInfo.author,
                    licenseUrl: eeccInfo.licenseUrl,
                    license: eeccInfo.license,
                  },
                }, type: 'photo' })}
              />
            </Box>
          </>
        ) : (
          <Box height='75px'></Box>
        ),
    },
  ];

  return (
    <>
      <Cards ref={cardsRef} values={eeccs[eeccTypeFilter]} variant="condensed" columns={columns} showPagination />
      <footer>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          { Object.keys(categoryList).map(categoryElement =>
            <Box key={categoryElement} display="flex" alignItems="center" marginRight={1} marginBottom={1}>
              <Box className={classes.boxCategoryFooter} style={{
                backgroundColor: categoryList[categoryElement].backgroundColor || categoryList['DD'].backgroundColor,
                color: categoryList[categoryElement].textColor || categoryList['DD'].textColor,
              }}>
                {categoryElement}
              </Box>
              <Typography className={classes.categoryText} noWrap>
                {categoryList[categoryElement].text}
              </Typography>
            </Box>,
          )}
        </Box>
      </footer>
    </>
  );
};

EeccList.propTypes = {
  eeccs: PropTypes.object,
  eeccTypeFilter: PropTypes.string,
  actions: PropTypes.object,
};


export { EeccList };