import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, TextField, InputLabel } from '@material-ui/core';

import { DialogButton, AlertWrapper } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const GenerateLinkCodeDialog = props => {

  const { actions } = props;

  const [ captureCompanyName, setCaptureCompanyName ] = useState('');

  const { fetchStatus, errorMessage, hasError, handleConfirm } = useFetchStatus({
    confirmFn: () => actions.generateLinkCode(captureCompanyName),
    closeFn: actions.closeDialog,
  });

  return (
    <>
      <DialogTitle id="form-dialog-title">Generar código de vinculación</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Box my={3}>
            <InputLabel required shrink>Empresa de capture a vincular</InputLabel>
            <TextField
              value={captureCompanyName}
              onChange={e => setCaptureCompanyName(e.target.value)}
              fullWidth
              autoFocus
              required
              autoComplete="off"
              variant="outlined"
              size="small"
              placeholder="Ej: Nviro"
            />
          </Box>
          {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog} color="secondary">Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Crear</DialogButton>
      </DialogActions>
    </>
  );
};

GenerateLinkCodeDialog.propTypes = {
  actions: PropTypes.object.isRequired,
};


export {
  GenerateLinkCodeDialog,
};
